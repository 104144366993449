var testimonial_no = 0;
var no_tesimonials = 0;

var gc_no = 2;

var bgSpeed = 0.5;

function parallaxEl(el, parent) {
  var winH = window.innerHeight;
  var supportPageOffset = window.pageXOffset !== undefined;
  var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
  var winY = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

  var parentY = parent.getBoundingClientRect().top + window.scrollY;
  var parentH = parent.clientHeight;

  if (parent.dataset.parnav == '1') {
    winY += 0.07 * winH;
    winH -= 0.07 * winH;
  }
  var safety_scroll = winH * 0.25;
  if ((parentY < winY + winH + safety_scroll) && (parentY + parentH > winY - safety_scroll)) {
    var maxScroll = winH + parentH
    var maxMovement = (winH + parentH) * bgSpeed;
    var initialOffset =  -maxMovement / 2;

    var parallaxPerc = (winY - (parentY - winH)) / maxScroll;

    var dynamic_offset = initialOffset + maxMovement * parallaxPerc;

    var el_adj = dynamic_offset;
  }
  else {
    var el_adj = 0;
  }
  el.style.top = 'calc(50% + ' + el_adj + 'px)';
}

if (document.readyState === "interactive") {
  const event = document.createEvent("Event");
  event.initEvent("turbolinks:load", true, true);
  document.dispatchEvent(event);
}

document.addEventListener("turbolinks:load", function() {
  var parallax_imgs = document.getElementsByClassName('parallax-img');

  window.onscroll = function() {

    for (let i_no = 0; i_no < parallax_imgs.length; i_no++) {
      var el = parallax_imgs[i_no];
      var parent = parallax_imgs[i_no].parentElement;

      parallaxEl(el, parent);
    }
  }
});
